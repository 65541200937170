import "./chart.scss";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { useEffect, useState } from "react";


const Chart = ({ aspect, title }) => {
  const[students, setStudents]= useState([])
  const[filterData, setFilterData]= useState(null)
  const[absentfilter, setAbsentFilterData]= useState(null)

  let arr = [];

  useEffect(() => {
    fetch(`https://spo.apigo.click/services/getstudentsattendancedashboard`)
    .then((response) => response.json())
    // .then((actualData) => (actualData.data).map((set)=>console.log(set.attendance === "P" ? arr.push(set):null)))
    .then((actualData) => (setStudents(actualData.data)))
    // .then(()=>(setStudents(arr)))
   
   }, []);
   

  // This function takes a string and creates a date object from the string and return the month. 
const findDays = (datestring) => {
  let date = new Date(datestring);
  return date.getDay();
};

const makeDateArray = (orders) => {
  let monthFreq = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // you can use Array.fill() but for clarity
  // now you loop over each object in the data array
  for (const order of orders) {
      const month = parseInt(findDays(order?.createdAt));
      // Months are from 0-11 in JS so it matches directly with array index
      monthFreq[month] = monthFreq[month] + 1;
  }
  return monthFreq;
};

// console.log(students)
//Yearly wise
const present = students?.filter(item => item.attendance === 'P')
const absent = students?.filter(item => item.attendance !== 'P')
const pFilterArray = makeDateArray(filterData === null ? present : filterData?.filter(item => item.attendance === 'P')); // present filter data
const aFilterArray = makeDateArray(absentfilter === null ? absent : absentfilter?.filter(item => item.attendance !== 'P')); // absent filter data
console.log(pFilterArray)

const onChange = e => {
  let newArr = [];
  let absentArray =[]
  present.map((set)=>( set.createdAt.substr(0,4) === e.target.value ? newArr.push(set) : null))
  absent.map((set)=>( set.createdAt.substr(0,4) === e.target.value ? absentArray.push(set) : null))
  setFilterData(newArr)
  setAbsentFilterData(absentArray)
};

const anydata = [
  {
    name: "Jan",
    present: pFilterArray[0] ,
    absent : aFilterArray[0]
  },
  {
    name: "Feb",
    present: pFilterArray[1],
    absent : aFilterArray[1]
  },
  {
    name: "March",
    present: pFilterArray[2],
    absent : aFilterArray[2]

  },
  {
    name: "Apr",
    present: pFilterArray[3],
    absent : aFilterArray[3]

  },
  {
    name: "May",
    present: pFilterArray[4],
    absent : aFilterArray[4]

  },
  {
    name: "June",
    present: pFilterArray[5],
    absent : aFilterArray[5]

  },
  {
    name: "July",
    present: pFilterArray[6],
    absent : aFilterArray[6]

  },
   {
    name: "Aug",
    present: pFilterArray[7],
    absent : aFilterArray[7]

  }, 
  {
    name: "Sep",
    present: pFilterArray[8],
    absent : aFilterArray[8]

  }, 
  {
    name: "Oct",
    present: pFilterArray[9],
    absent : aFilterArray[9]

  },
  {
    name: "Nov",
    present: pFilterArray[10],
    absent : aFilterArray[10]

  },
  {
    name: "Dec",
    present: pFilterArray[11],
    absent : aFilterArray[11]

  },
];


const getDropList = () => {
  const year = new Date().getFullYear();
  return (
    Array.from( new Array(2), (v,i) =>
      <option key={i} value={year-i}>{year-i}</option>
    )
  );
};

  return (
    <div className="chart">
       <div className="yearly">
      <div>Overall Students Attendance</div>
        <select onChange={onChange}>
          {getDropList()}
        </select>
      </div>

    <ComposedChart
      width={700}
      height={400}
      data={anydata}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      }}
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis dataKey="name" scale="band" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="present" barSize={20} fill="#413ea0" />
      <Bar dataKey="absent" barSize={20} fill="#ff7300" />

    </ComposedChart>
    </div>
  );
};

export default Chart;
